const selfData = {
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                label: "任务名称",
                prop: "RWMC",
                minWidth: "200"
            }, {
                label: "任务优先级别",
                prop: "RWYXJB_DESC",
            }, {
                label: "专家姓名",
                prop: "ZJXM",
            }, {
                label: "专家单位",
                prop: "SZDW",
                minWidth: "180"
            }, {
                label: "任务生成时间",
                prop: "RWSCSJ",
                minWidth: '120'
            }, {
                label: "停留工作日",
                prop: "TLGZR",
                minWidth: '100'
            }, {
                label: "状态",
                prop: "RWZT_DESC",
            }, {
                label: "专家建议",
                prop: "ZJJY",
            }],
            // 操作列
            columnsBtn: {
                buttonData: [{
                    isShow: true,
                    btnType: 'text',
                    operateType: 'columnRead',
                    text: '查看',
                    textDecoration: 'underline',
                    paddingRight: '20px',
                    color: '#2a62d5',
                    showMethod: 'showReadBtn',
                }, {
                    isShow: true,
                    btnType: 'text',
                    operateType: 'columnEdit',
                    text: '修改',
                    textDecoration: 'underline',
                    color: '#1989fa',
                    showMethod: 'showEditBtn',
                }, {
                    isShow: true,
                    btnType: 'text',
                    operateType: 'columnDisposeProcess',
                    text: '处置进程',
                    textDecoration: 'underline',
                    color: '#1989fa',
                    showMethod: 'showDisposeProcessBtn',
                }, {
                    isShow: true,
                    btnType: 'text',
                    operateType: 'columnDisposeRead',
                    text: '查看处置',
                    textDecoration: 'underline',
                    color: '#2a62d5',
                    showMethod: 'showDisposeReadBtn',
                }],
                width: 150,
            }
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    snSearch: {
        selectData: [{
            label: '任务优先级别',
            clearable: true,
            placeholder: "请选择任务优先级别",
            list: [],
            optionLabel: 'Name',
            optionValue: 'Code',
            value: 'RWYXJB',
            operateType: 'search',
            isShow: true
        }, {
            label: '状态',
            clearable: true,
            placeholder: "请选择状态",
            list: [],
            optionLabel: 'Name',
            optionValue: 'Code',
            value: 'RWZT',
            operateType: 'search',
            isShow: true
        }],
    },
    snButton: {
        buttonData: [{
            isShow: true,
            btnType: 'button',
            operateType: 'buttonNew',
            name: '新建任务',
            round: true,
            backColor: '#28ccd9',
            // }, {
            //     btnType: 'button',
            //     operateType: 'buttonExport',
            //     name: '导出',
            //     round: true,
            //     backColor: '#ffcc66',
            //     color: '#fff'
        }]
    }
};
export { selfData };