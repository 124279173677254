<template>
    <div>
        <sn-table-group :tableGroupAttributes="tableGroupAttributes" @handleChange="handleChange" :commonMethods="commonMethods"></sn-table-group>
        <sn-dialog :dialogData.sync="dialogDataDisPro">
            <template>
                <DisposeProcess :dataSource="currentDataDisPro" @getTableData="getTableData"></DisposeProcess>
            </template>
        </sn-dialog>
        <sn-dialog :dialogData.sync="dialogDataAssRes">
            <template>
                <AssessResult :dataSource="currentDataAssRes"></AssessResult>
            </template>
        </sn-dialog>
    </div>
</template>
<script>

import { commonData } from '../sn-table-group-common';
import { selfData } from './model/sn-table-group';
import commonMethodMixin from '../commonMethodMixin';
import { mapActions } from 'vuex';
import { commonMethods } from './model/table-methods';
import DisposeProcess from './dispose_process';
import AssessResult from '../components/assess-result'
export default {
    name: 'consulting_expert_grid',
    mixins: [commonMethodMixin],
    data() {
        return {
            commonData,
            commonMethods,
            selfData,
            infoPage: 'consulting_expert_form',
            // 筛选项
            keyword: '', // 关键字
            itemid: '8020513', // 业务编号
            dialogDataDisPro: {
                title: '处置进程',
                dialog: false,
                width: '',
                class: 'deepBlueDialog',
                action: false,
            },
            dialogDataAssRes: {
                title: '专家建议',
                dialog: false,
                width: '',
                class: 'deepBlueDialog',
                action: false,
            },
            currentDataDisPro:{
                data: null,
                state: '',
            },
            currentDataAssRes: {},
            jjcdValue: '', //紧急程度选择的值
            rwztValue: '', //状态选择的值
        };
    },
    components:{
        DisposeProcess,
        AssessResult
    },
    mounted() {
        this.getJjcdList();
        this.getRwztList();
    },
    methods: {
        ...mapActions([
            'DeleteData',
            'GetExpertTaskList', //获取专家任务列表
            'GetEnumItem', //获取枚举列表
        ]),
        async getTableData() {
            if(this.dialogDataDisPro.dialog){
                this.dialogDataDisPro.dialog = false;
            }
            // 处理筛选
            this.jjcdValue = '';
            this.rwztValue = '';
            if (this.searchData && this.searchData.length > 0) {
                this.searchData.forEach((item) => {
                    if (
                        (typeof item.value === "string" && item.value !== "") ||
                        (typeof item.value === "object" &&
                            Object.keys(item.value).length > 0)
                    ) {
                        if (item.type === "RWYXJB") {
                            this.jjcdValue = item.value;
                        } else if (item.type === "RWZT") {
                            this.rwztValue = item.value;
                        }
                    }
                });
            }
            
            this.tableGroupAttributes.loading = true;
            let res = await this.GetExpertTaskList({
                rwyxjb: this.jjcdValue, //紧急程度
                rwzt: this.rwztValue, //任务状态
                keyword: this.keyword,	//查询关键字
                pageIndex: this.tableGroupAttributes.snPage.currentPage,	//页索引
                pageSize: this.tableGroupAttributes.snPage.tiaoshu,	//每页最大记录数
            })
            this.tableGroupAttributes.snTable.table.data = res.Data || [];
            this.tableGroupAttributes.snPage.count = Number(res.Count) || 0;
            this.tableGroupAttributes.loading = false;
            this.tableGroupAttributes.snTable.columns.columnsData[
                this.tableGroupAttributes.snTable.columns.columnsData.length - 2
            ] = {
                prop: "RWZT",
                label: "状态",
                width: "160",
                transList: {
                    0: "未提交",
                    1: "等待专家评估",
                    2: "专家已评估",
                    3: "评估以及处置已结束"
                },
                colorList: {
                    0: "#088eb2",
                    1: "#088eb2",
                    2: "#090",
                    3: "#090"
                },
                // textDecoration: 'underline',
            };
            this.tableGroupAttributes.snTable.columns.columnsData[
                this.tableGroupAttributes.snTable.columns.columnsData.length - 1
            ] = {
                label: "专家建议",
                prop: "ZJJY",
                width: "90",
                isReplaceText: true,
                replaceText: '预览',
                replaceChar: '--',
                textStyle: {
                    color: '#2a62d5',
                    cursor: 'pointer'
                },
                operateType: "columnText"
            };
        },
        // 获取任务优先级别列表
        async getJjcdList(){
            this.tableGroupAttributes.snSearch.selectData[0].list = [];
            let result = await this.GetEnumItem({
                Enumid: '5f00859b-70d2-11ea-bd8d-000c2977b7fd'
            });
            if(result && result.length>0){
                this.tableGroupAttributes.snSearch.selectData[0].list = result;
            }
        },
        // 获取任务状态列表
        async getRwztList(){
            this.tableGroupAttributes.snSearch.selectData[1].list = [];
            let result = await this.GetEnumItem({
                Enumid: '5a1b53bd-6f35-11ea-bd8d-000c2977b7fd'
            });
            if(result && result.length>0){
                this.tableGroupAttributes.snSearch.selectData[1].list = result;
            }
        },
        // 删除数据
        // eslint-disable-next-line no-unused-vars
        async delData(id, done, instance) {
            let res = await this.DeleteData({
                ID: id,
                itemid: this.itemid
            });
            this.delOver(res, done);
        },
        columnTextClick(val){
            this.dialogDataAssRes.dialog = true;
            this.currentDataAssRes = val[0];
        },
        columnDisposeProcessClick(val){
            this.dialogDataDisPro.dialog = true;
            this.currentDataDisPro = {
                data: val[0],
                state: '1',
            }
        },
        columnDisposeReadClick(val){
            this.dialogDataDisPro.dialog = true;
            this.currentDataDisPro = {
                data: val[0],
                state: '2',
            }
        }
    },
};
</script>
<style lang="scss" scoped>

</style>