const commonMethods = {
    // 查看
    showReadBtn(row) {
        if (Number(row.RWZT) === 0) {
            return false;
        } else {
            return true;
        }
    },
    // 修改
    showEditBtn(row) {
        if (Number(row.RWZT) === 0) {
            return true;
        } else {
            return false;
        }
    },
    // 处置进程
    showDisposeProcessBtn(row) {
        if (Number(row.RWZT) === 2) {
            return true;
        } else {
            return false;
        }
    },
    // 查看进程
    showDisposeReadBtn(row) {
        if (Number(row.RWZT) === 3) {
            return true;
        } else {
            return false;
        }
    }
};
export { commonMethods };