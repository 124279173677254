<template>
    <div class="assess-result">
        <div class="assess-result-item">
            <div class="assess-label">评估结果简述：</div>
            <div class="assess-p">{{pgjgjsText}}</div>
        </div>
        <div class="assess-result-item">
            <div class="assess-label">评估报告：</div>
            <div class="assess-p">
                <div v-for="(item,index) in pgbgList" :key="index">
                    <a class="assess-a" :href="item.CCLJ" target="_blank">{{item.WDMC}}</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
    data() {
        return {
            pgjgjsText: '',
            pgbgList: [],
        }
    },
    props:{
        dataSource: {
            type: Object,
            default: () => { }
        }
    },
    watch: {
        dataSource() {
            this.getAssessData();
        }
    },
    mounted() {
        this.getAssessData();
    },
    methods: {
        ...mapActions([
            'GetDealProcessExpert', //获取专家评估结果信息
        ]),
        async getAssessData() {
            this.pgjgjsText = '';
            this.pgbgList = {};
            if (this.dataSource && this.dataSource.ID) {
                let result = await this.GetDealProcessExpert({
                    zjrwid: this.dataSource.ID
                });
                if(result && result.length>0){
                    this.pgjgjsText = result[0].Zjjy;
                    this.pgbgList = result[0].PGFJ;
                }
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.assess-result {
    width: 100%;
    border: 1px solid #d7d7d7;
    padding: 10px;
    .assess-result-item {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start; // align-items: center;
        margin-bottom: 10px;
        .assess-label {
            width: 110px;
            padding-right: 5px;
            text-align: right;
            font-weight: bold;
        }
        .assess-p {
            width: calc(100% - 100px);
            line-height: 24px;
            .assess-a {
                color: #00f;
                cursor: pointer;
            }
        }
    }
}
</style>
