<template>
    <div class="dispose-process-base scrollbar">
        <AssessResult :dataSource="dataSource && dataSource.data ? dataSource.data : {}"></AssessResult>
        <div class="no-data" v-if="state === '2' && (!axisList || axisList.length == 0)">暂无处置进程</div>
        <div v-else>
            <div class="time-axis">
                <div class="axis-line"></div>
                <div class="axis-cicle scrollbarX">
                    <div class="axis-cicle-item" v-show="state !== '2'" @click="handleAxisClick('add')">
                        <div class="axis-cicle-out">
                            <div class="axis-cicle-in" :style="{background: currentAxisID === 'add' ? '#09f' : '#ccc'}"></div>
                        </div>
                        <div class="axis-time">{{currentAxisID === 'add' && formData && formData.CZSJ ? formData.CZSJ.substr(0,10) : ''}}</div>
                    </div>
                    <div class="axis-cicle-item" v-for="(item,index) in axisList" :key="index" @click="handleAxisClick(item)">
                        <div class="axis-cicle-out">
                            <div class="axis-cicle-in" :style="{background: item.ID === currentAxisID ? '#09f' : '#ccc'}"></div>
                        </div>
                        <div class="axis-time">{{item.CZSJ ? item.CZSJ.substr(0,10) : ''}}</div>
                    </div>
                </div>
            </div>
            <div class="dispose-content">
                <el-form class="dispose-form" ref="form" :model="formData" label-width="120px" label-position="right" size="small">
                    <el-form-item label="处置事件">
                        <el-input v-model="formData.CZMC" :disabled="!isEdit"></el-input>
                    </el-form-item>
                    <el-form-item label="事件描述">
                        <el-input type="textarea" :rows="6" v-model="formData.CZMS" :disabled="!isEdit"></el-input>
                    </el-form-item>
                    <el-form-item label="处置时间">
                        <el-date-picker type="datetime" placeholder="请选择处置时间" v-model="formData.CZSJ" style="width: 100%;" value-format="yyyy-MM-dd HH:mm:ss" :disabled="!isEdit" :picker-options="czsjPickerOptions"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="相关附件">
                        <div v-if="fileList && fileList.length>0 && !isEdit">
                            <div v-for="(item,index) in fileList" :key="index">
                                <a class="assess-a" :href="item.url" target="_blank">{{item.name}}</a>
                            </div>
                        </div>
                        <!-- <el-input v-if="!fileList || fileList.length===0 && !isEdit" :disabled="true" placeholder="未上传"></el-input> -->
                        <div class="no-file" v-if="!fileList || fileList.length===0 && !isEdit">未上传</div>
                        <ExpertUpload v-if="isEdit" @successUpload="successUpload" @handleDelFile="handleDelFile" :fileList="fileList"></ExpertUpload>
                    </el-form-item>
                    <el-form-item label="是否已完成处置">
                        <el-radio-group v-model="formData.SFWCCZ" :disabled="!isEdit">
                            <el-radio :label="1">是</el-radio>
                            <el-radio :label="0">否</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-button class="dispose-form-btn" v-if="isEdit" type="primary" size="mini" @click="onSubmit">保存</el-button>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import AssessResult from '../components/assess-result'
import {mapActions} from 'vuex'
import ExpertUpload from "../components/expert-upload"
export default {
    data() {
        return {
            axisList: [],
            formData: {
                ZJRWID: '',
                CZMC: '',
                CZMS: '',
                CZSJ: '',
                SFWCCZ: 0,
                fjIds: '',
            },
            fileList: [],
            state: '',
            isEdit: false,
            currentAxisID: '',
            czsjPickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
            },
        }
    },
    props: {
        dataSource: {
            type: Object,
            default: () => { }
        }
    },
    components: {
        AssessResult,
        ExpertUpload
    },
    watch: {
        dataSource() {
            console.log(1, 'watch:', this.dataSource);
            this.handleDataSource();
        }
    },
    mounted() {
        console.log(1, 'mounted:', this.dataSource);
        this.handleDataSource();

        // eslint-disable-next-line no-undef
        $(document).ready(function(){
            let tmp = document.getElementsByClassName("el-textarea__inner");
            if(tmp && tmp.length > 0 && tmp[0].className){
                tmp.forEach(item => {
                    item.className = "el-textarea__inner scrollbar";
                })
            }
        });
    },
    methods: {
        ...mapActions([
            'SaveDealProcess', //保存处置进程服务
            'GetDealProcess', //获取处置进程界面服务
            "DelFileInfo", //删除附件
        ]),
        // 处理传入的任务数据
        handleDataSource() {
            if (this.dataSource && this.dataSource.state && this.dataSource.state !== '') {
                this.state = this.dataSource.state;
            }
            this.getAxisList();
        },
        // 请求时间轴数据
        async getAxisList() {
            this.axisList = [];
            let result = await this.GetDealProcess({
                zjrwid: this.dataSource.data.ID || ''
            });
            if (result && result.length > 0) {
                this.axisList = result;
                if(this.axisList[0].SFWCCZ === 1){
                    this.state = '2'
                }
            }
            if(this.state === '2'){
                if(this.axisList && this.axisList.length > 0){
                    this.handleAxisClick(this.axisList[0]);
                }
            }else{
                this.handleAxisClick('add');
            }
        },
        // 点击时间轴
        handleAxisClick(val) {
            if (val) {
                if (val.ID) {
                    this.currentAxisID = val.ID;
                    this.getFormData(val);
                } else if (val === 'add') {
                    this.currentAxisID = val;
                    this.formData = {};
                    this.fileList = [];
                    this.isEdit = true;
                }
            }
        },
        // 请求处置进程数据
        getFormData(val) {
            this.formData = {};
            this.fileList = [];
            let fjIdsArr = [];
            if (val && val.ID) {
                this.formData = val;
                this.formData.SFWCCZ =  this.formData.SFWCCZ === 1 ? 1 : 0;
                if(this.formData.CZFJ && this.formData.CZFJ.length>0){
                    this.formData.CZFJ.forEach(item=>{
                        this.fileList.push({
                            Guid: item.ID,
                            name: item.WDMC,
                            url: item.CCLJ,
                        })
                        fjIdsArr.push(item.ID);
                    })
                }
                this.formData.fjIds = fjIdsArr.toString();
                
                if(this.state === '2'){
                    this.isEdit = false;
                }else{
                    this.isEdit = this.formData.SFWCCZ === 1?false:true;
                }
            }
        },
        // 处置进程保存
        async onSubmit() {
            console.log('submit!');
            this.formData.ZJRWID = this.dataSource.data.ID;
            let result = await this.SaveDealProcess(this.formData);
            if(result.IsSuccess){
                this.$message({
                    type: 'success',
                    message: '保存成功'
                });
                this.getAxisList();
                if(this.formData.SFWCCZ === 1){
                    this.$emit("getTableData")
                }
            }else{
                this.$message({
                    type: 'error',
                    message: '保存失败'
                });
            }
        },
        // eslint-disable-next-line no-unused-vars
        successUpload(val, fileIndex){
            this.formData.fjIds = val;
        },
        // eslint-disable-next-line no-unused-vars
        async handleDelFile(val, fileIndex){
            let fjids = '';
            let canDelFileList = this.fileList;

            if(canDelFileList && canDelFileList.length>0){
                canDelFileList.forEach(item=>{
                    if(item.Guid === val){
                        fjids = val;
                    }
                })
            }

            let result = await this.DelFileInfo({
                fjids: fjids
            });
            if(result.IsSuccess){
                this.$message({
                    type: 'success',
                    message: '移除成功'
                });
            }else{
                this.$message({
                    type: 'error',
                    message: '移除失败'
                });
            }
        },
    },
}
</script>
<style lang="scss" scoped>
$axisCicleOutWidth: 16px;
$btnColor: #09f;
.dispose-process-base {
    width: 100%;
    max-height: 550px;
    padding: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    .no-data{
        width: 100%;
        height: 30px;
        text-align: center;
        padding: 30px 0;
        font-size: 16px;
    }
    .time-axis {
        position: relative;
        width: calc(100% - 20px);
        margin: 20px 10px 0 10px;
        .axis-line {
            position: absolute;
            top: $axisCicleOutWidth/2;
            left: 0;
            width: 100%;
            height: 0;
            border-top: 1px dashed #d0d0d0;
        }
        .axis-cicle {
            // display: box;
            display: -webkit-box;
            display: -moz-box;
            -webkit-box-pack: center;
            -moz-box-pack: center;
            width: 100%;
            overflow-x: auto;
            .axis-cicle-item {
                width: 150px;
                cursor: pointer;
                .axis-cicle-out {
                    width: $axisCicleOutWidth;
                    height: $axisCicleOutWidth;
                    margin-left: 50%;
                    transform: translateX(-50%);
                    border: 1px solid $btnColor;
                    border-radius: 50%;
                    .axis-cicle-in {
                        width: 10px;
                        height: 10px;
                        margin-left: 50%;
                        margin-top: 50%;
                        transform: translate(-50%, -50%);
                        border-radius: 50%;
                        background: $btnColor;
                    }
                }
                .axis-time {
                    width: 100%;
                    text-align: center;
                    margin-top: 8px;
                    padding-bottom: 10px;
                }
            }
        }
    }
    .dispose-content {
        width: 100%;
        padding: 10px;
        margin-top: 10px;
        .dispose-form-btn {
            margin-left: 50%;
            transform: translateX(-50%);
        }
    }
    .assess-a {
        color: #00f;
        cursor: pointer;
    }
    .no-file{
        color: #c0c4cc;
    }
}
</style>
