<template>
    <div>
        <el-upload
            class="upload-demo"
            :action="`${uploadURL}UpLoad/FileSave?LJ=ZJZX`"
            multiple
            :limit="5"
            :on-success="successUpload"
            :on-error="errorUpload"
            :on-preview="handlePreview"

            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            :on-exceed="handleExceed"
            :file-list="fileList"
        >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">上传文件不超过5个</div>
        </el-upload>
    </div>
</template>
<script>
export default {
    data() {
        return {
            uploadURL: window.REQUEST_URL,
            uploadFileList:[],
        };
    },
    props:{
        fileList: {
            type: Array,
            default: ()=>[]
        },
        fileIndex: {
            type: String,
            default: '1',
        }
    },
    mounted(){
        this.uploadFileList = [];
    },
    watch:{
        fileList(){
            this.uploadFileList = [];
        }
    },
    methods: {
        successUpload(response, file, fileList) {
            // Guid: "5177a28f-9a28-4546-a117-fcc679447a14"
            // FilePath: "http://172.16.103.115:2019/basicData//5177a28f-9a28-4546-a117-fcc679447a14.png"
            // FilePath_ex: "basicData//5177a28f-9a28-4546-a117-fcc679447a14.png"
            // FileName: "搜索框1.png"

            if (response.IsSuccess) {
                let fileListStr = [];
                console.log("上传成功", response, file, fileList);
                let result = response.ResultValue[0];
                this.uploadFileList.push(result);
                this.uploadFileList.forEach(item=>{
                    if(item.Guid){
                        fileListStr.push(item.Guid);
                    }
                })
                this.$emit("successUpload", fileListStr.toString(), this.fileIndex);
            }
        },
        // eslint-disable-next-line no-unused-vars
        errorUpload(error, file, fileList){
            console.log('上传失败');
            this.$message({
                message: '上传失败',
                type: 'error'
            });
        },

        // eslint-disable-next-line no-unused-vars
        handleRemove(file, fileList) {
            console.log(111101010,file);
            let fjids = file.Guid || '';
            if(fjids){
                this.$emit("handleDelFile", fjids, this.fileIndex);
            }else{
                if(this.uploadFileList && this.uploadFileList.length > 0){
                    let fileListStr = [];
                    for(let i = 0; i < this.uploadFileList.length; i++){
                        if(this.uploadFileList[i].Guid === file.response.ResultValue[0].Guid){
                            this.uploadFileList.splice(i, 1);
                        }else{
                            fileListStr.push(this.uploadFileList[i].Guid);
                        }
                    }
                    this.$emit("successUpload", fileListStr.toString(), this.fileIndex);
                }
            }
        },
        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 5 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },
        // eslint-disable-next-line no-unused-vars
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
        },
        handlePreview(file) {
            console.log("file:", file);
            if (file.url) {
                window.open(file.url);
            } else if (file.CCLJ) {
                window.open(file.CCLJ);
            } else if (file.response && file.response.ResultValue && file.response.ResultValue.length > 0 && file.response.ResultValue[0].FilePath) {
                window.open(file.response.ResultValue[0].FilePath);
            }
        },
    }
}
</script>
